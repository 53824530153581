import request from '../api-gateway/request'
import requestLaravel from '../laravel-web-app/request'
import requestLaravelWhiteLabel from '../laravel-web-app/requestWhiteLabel'

async function login(data){
     return request({
        method: "POST",
        url: "/auth",
        data: data,
    }).then((response) => response.data);
}

async function laravelLogin(data, current_customer_id)
{
    //To load xsrf token on browser
    //any request to laravel load xsrf token on browser
    await requestLaravel({
        method: "GET",
        url: "/xsrfpoli",
        withCredentials: true
    });

    return requestLaravel({
        method: "POST",
        url: "/login?current_customer_id="+current_customer_id,
        data: data,
        withCredentials: true,
        maxRedirects: 0,
        redirect: 'manual'
    })
}

async function loginApiGatewayWl(data) {
  return request({
    method: 'POST',
    url: '/authWithToken?',
    data: data,
  }).then((response) => response.data);
}

async function laravelLoginWlWithToken(data, current_customer_id, laravel_base_url_wl) {
  await requestLaravelWhiteLabel({
    method: 'GET',
    url: laravel_base_url_wl + '/xsrfpoli',
    withCredentials: true,
  });

  return requestLaravelWhiteLabel({
    method: 'POST',
    url: laravel_base_url_wl + '/loginWithToken?current_customer_id=' + current_customer_id,
    data: data,
    withCredentials: true,
    maxRedirects: 0,
    redirect: 'manual',
  });
}

async function laravelLoginWl(data, current_customer_id, laravel_base_url_wl) {
  await requestLaravelWhiteLabel({
    method: 'GET',
    url: laravel_base_url_wl + '/xsrfpoli',
    withCredentials: true,
  });

  return requestLaravelWhiteLabel({
    method: 'POST',
    url: laravel_base_url_wl + '/login?current_customer_id=' + current_customer_id,
    data: data,
    withCredentials: true,
    maxRedirects: 0,
    redirect: 'manual',
  });
}

async function laravelLogout()
{
    return requestLaravel({
        method: "POST",
        url: "/logout?redirect_logout_spa=false",
        withCredentials: true,
        maxRedirects: 0, //Evitar que o laravel redirecione para o spa e entrar em loop. Browsers will always follow HTTP redirects, need this to abort redirect.
        redirect: 'manual', //Evitar que o laravel redirecione para o spa e entrar em loop
    })
}

export {
  login,
  laravelLogin,
  loginApiGatewayWl,
  laravelLoginWlWithToken,
  laravelLoginWl,
  laravelLogout,
};
