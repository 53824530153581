import {
  addUploadToList,
  updateChatDropzone,
  updateShopppingCart,
  markChatShopppingCartServerSynced,
} from './actions';
import store from '../../../store';
import { v4 as uuidv4 } from 'uuid';
import filesize from 'filesize';
import { toast } from 'react-toastify';
import checkAvatar from '../../../utils/checkAvatar';
import formatPhone from '../../../utils/formatPhone';

export function matchChannelToExternal(chat, channels) {
  let selectedChannel = false;
  let externals = chat.contact.externals;

  if (!chat.view && !chat.contact) {
    return chat;
  }

  // Caso exista algum external já selecionado como true ele nem entra no escopo de escolha
  if (externals.find((external) => external.selected === true)) {
    return chat;
  }

  // Esse contato já existente e já tem conversas na plataforma
  if (externals && externals.length) {
    // O External padrão será selecionado aqui e usará como base a última mensagem
    if (chat.last_message && chat.last_message.contact_uid && externals) {
      let findExternalIndexLastMessage = externals.findIndex(
        (external) => external.uid === chat.last_message.contact_uid
      );

      if (findExternalIndexLastMessage > -1) {
        externals[findExternalIndexLastMessage].selected = true;
      }
    }

    if (chat.last_message && chat.last_message.channel_id) {
      if (channels) {
        let channel = channels.find(
          (channel) => channel.id === chat.last_message.channel_id
        );

        // Se o canal da ultima mensagem não existir
        if (!channel) {
          channel = channels[0];
        }

        if (channel && channel.channel_id && channel.channel_id > 0) {
          let index = externals.findIndex(
            (external) => external.channel_id === channel.channel_id
          );

          if (index > -1) {
            selectedChannel = true;
            chat.view.selectedChannel = channel;
            externals[index].selected = true;
          }
        }
      }
    }
  }

  // Aqui entram os contatos que foram adicionados ou importados na plataforma
  if (!selectedChannel) {
    if (channels.length && externals && externals.length) {
      let key_aux = null;
      let selected = false;
      for (let i in channels) {
        for (let j in externals) {
          externals[j].selected = false;
          if (channels[i].channel_id === externals[j].channel_id) {
            if (chat.last_message && chat.last_message.channel_id) {
              if (chat.last_message.channel_id === channels[i].id) {
                chat.view.selectedChannel = channels[i];
                externals[j].selected = true;
                selected = true;
                break;
              } else {
                key_aux = j;
              }
            } else {
              // Se a pessoa nao tem ultima mensagem ou seja CONTATO NOVO
              chat.view.selectedChannel = channels[i];
              externals[j].selected = true;
              selected = true;
              break;
            }
          }
        }
      }
      if (!selected && key_aux !== null) {
        externals[key_aux].selected = true;
      }
    }
  }
  chat.contact.externals = externals;
  return chat;
}
// Essa função força que o canal selecionado seja o da última mensagem
// É chamado quando abre o chat OPEN_CHAT
export function forceLastMessageChannel(chat, channels) {
  if ((!chat.view && !chat.contact) || !channels || !chat.last_message) {
    return chat;
  }
  const selectedChannel = channels.find(
    (channel) => channel.id === chat.last_message.channel_id
  );

  if (selectedChannel && selectedChannel.id && selectedChannel.id > 0) {
    chat.view.selectedChannel = selectedChannel;
    chat.chat.channel_id = selectedChannel.id;
  } else {
    chat.view.selectedChannel = channels[0];
    chat.chat.channel_id = channels[0].id;
  }
  return chat;
}

export function findSelectedExternalId(chat) {
  let selected = chat.contact.externals.find(
    (external) => external.selected === true
  );

  if (selected) return selected.id;

  return null;
}

export function findChannelId(chat) {
  if (chat.view && chat.view.selectedChannel) {
    return chat.view.selectedChannel.id;
  }

  return null;
}

// Função para pegar todos os dados do canal que tem o ID passado
export function getInfosChannel(channels, channelId) {
  for (let i = 0; i < channels.length; i++) {
    if (channels[i].id === channelId) {
      return channels[i];
    }
  }
  return null;
}

export function mountMessage(chat, data, type) {
  type = type || 'chat';
  let customer_id = 0;
  if (chat.contact && chat.contact.externals) {
    for (let i in chat.contact.externals) {
      if (chat.contact.externals[i].selected) {
        customer_id = chat.contact.externals[i].customer_id;
      }
    }
  }
  let message = {
    id: null,
    cuid:
      's-' +
      customer_id +
      '-' +
      chat?.view?.selectedChannel?.id +
      '-' +
      chat?.contact?.id +
      '-msg-' +
      new Date().getTime(),
    contact_id: chat?.contact?.id,
    ack: '', //enviando
    channel_id: chat?.view?.selectedChannel?.id,
    uid: null,
    body: null,
    dir: 'o',
    dtm: Math.floor(Date.now() / 1000),
    status: -1,
    updated_at: {
      date: new Date().toJSON().replace(/[a-zA-Z]+/g, ' '),
      timezone_type: 3, //alterar aqui depois, para outros timezones
      timezone: 'UTC',
    },
    mimetype: null,
    caption: null,
    size: null,
    lat: null,
    lng: null,
    url: null,
    vcard: null,
    thumb: null,
    author_name: chat?.contact?.name,
    author_uid: chat?.contact?.phone,
    type: type,
  };
  if (type === 'media' || type === 'ptt') {
    // Quando envia mais de uma media ao mesmo tempo fica com mesmo cuid, isto esta causando bug, mostrando mesma media mais de uma vez
    const random = Math.floor(Math.random() * 10000);

    let file;
    //let typeFile;
    let preview;
    if (data.file) {
      ///geralmente entram aqui os que usam o dropzone
      file = data.file;
      //typeFile = data.typeFile;
      preview = data.preview;
    } else {
      file = data;
      if (type === 'ptt') {
        message.message_body = {
          url: data,
          url_base64: data,
        };
      } else {
        //typeFile = file.type.split(/\//)[0];
        preview = URL.createObjectURL(file);
      }
    }

    message.mimetype = file.type;
    message.file_name = file.name;
    message.caption = null;
    message.size = file.size;
    message.type = type;
    message.thumb = preview;
    message.cuid =
      'media-' +
      customer_id +
      '-' +
      chat?.chat?.channel_id +
      '-' +
      message.contact_id +
      '-msg-' +
      new Date().getTime() +
      '-digit-' +
      random;
  } else if (type === 'text' || type === 'chat') {
    message.cuid =
      's-' +
      customer_id +
      '-' +
      chat?.chat?.channel_id +
      '-' +
      message.contact_id +
      '-msg-' +
      new Date().getTime();
    message.body = data;
  } else if (type === 'product') {
    message.cuid =
      's-' +
      customer_id +
      '-' +
      chat?.chat?.channel_id +
      '-' +
      message.contact_id +
      '-msg-' +
      new Date().getTime();
    message.body = data;
  } else if (type === 'product') {
    message.cuid =
      's-' +
      customer_id +
      '-' +
      chat?.chat?.channel_id +
      '-' +
      message.contact_id +
      '-msg-' +
      new Date().getTime();
    message.body = data;
  } else if (type === 'payment_link') {
    message.cuid =
      's-' +
      customer_id +
      '-' +
      chat?.chat?.channel_id +
      '-' +
      message.contact_id +
      '-msg-' +
      new Date().getTime();
    message.body = data;
  }
  return message;
}

/**Insere uma mensagem desconhecida na lista de mensagens do chat. Obs.: a mensagem precisa conter o ID e o CUID */
export function addOrUpdateReceivedMessage(chat, newMessage) {
  if (!newMessage.id) {
    return chat;
  }
  let messageUpdated = false;

  let cuid_by_front = newMessage.cuid.replace('-' + newMessage.id, '');

  const updatingLastMessage = (checkMessage) => {
    if (
      (!chat.last_message || chat.last_message.dtm < checkMessage.dtm) &&
      checkMessage.dir !== 'l'
    ) {
      chat.last_message = checkMessage;
    }
  };

  /**
   * verificar se a mensagem que chegou já existia na lista de mensagens para atualizar seus dados
   */
  const indexMsgFound =
    chat.messages && chat.messages.length
      ? chat.messages.findIndex((message) => {
          return (
            message.cuid === cuid_by_front ||
            message.cuid === newMessage.cuid ||
            message.id === newMessage.id
          );
        })
      : undefined;

  if (indexMsgFound >= 0) {
    messageUpdated = true;
    chat.messages[indexMsgFound] = newMessage;
  } else {
    /**
     * adiciona nova mensagem na lista de mensagens do chat
     */
    chat.messages = chat.messages || [];
    chat.messages.push(newMessage);
  }

  updatingLastMessage(newMessage);

  return { ...chat, messageUpdated };
}

/**Adiciona os atributos messageType, isMedia, documentType, e converte vCard em objeto */
export function treatMessageType(message) {
  let messageType = message.type;

  /**
   * Manipulação da mensagem do tipo attachment
   */
  if (messageType === 'attachment') {
    if (message.text) {
      messageType = 'chat';
    } else if (message.url) {
      messageType = 'media';
    } else if (message.vCard || message.vcard) {
      messageType = 'vcard';
    } else if (message.location) {
      messageType = 'location';
    }
  }

  /**
   * Manipulação da mensagem do tipo media
   */
  if (messageType === 'media') {
    let typeMedia = message.url;

    /**
     * a informação de mimetype da mensagem pode vir em ambos os campos *mimetype*
     *  ou *mimeType* e nos casos do mimeType undefined irá avaliar mimetype
     */

    let messageMimeType = message.mimeType ?? message.mimetype;

    if (messageMimeType) {
      if (/^.+([0-9/a-z ]+); ([0-9a-z=]+)$/gi.test(messageMimeType)) {
        typeMedia = messageMimeType.replace(/;(.+)$/gi, '').replace(/\//, '.');
      } else {
        typeMedia = messageMimeType.replace(/\//, '.');
      }
    } else {
      message.mimeType = '';
    }

    if (
      typeMedia &&
      typeMedia.substr(-4).match(/(\.jpg|\.png|jpeg)(\?.+)?/gi)
    ) {
      messageType = 'image';
    } else if (/\.(zip|rar|7z)$/i.test(typeMedia)) {
      messageType = 'compacted';
    } else if (/(text\.plain)$/i.test(typeMedia)) {
      messageType = 'document';
    } else if (/\.(mp3|wma|aac|ogg|ac3|wav)$/i.test(typeMedia)) {
      messageType = 'audio';
    } else if (/\.(avi|mp4|mpeg|webm|mov|rmvb|mkv)$/i.test(typeMedia)) {
      messageType = 'video';
    } else if (/\.(pdf|psd|cdr|tiff)$/i.test(typeMedia)) {
      messageType = 'document';
    } else if (
      /^(application|text|model|x-conference|chemical).+/i.test(typeMedia)
    ) {
      messageType = 'document';
    } else {
      messageType = 'url';
    }
  }

  /**
   * Manipulação da mensagem do tipo vCard
   */

  if (messageType === 'vcard' || messageType === 'multi_vcard') {
    const input = (message.vCard || '') + '';

    const Re1 = /^(version|fn|title|org):(.+)$/i;
    const Re2 = /^([^:;]+);([^:]+):(.+)$/;
    const Re3 = /^item\d{1,2}\.(TEL|CEL):([0-9 \-\(\)\+]+)$/;
    const ReKey = /item\d{1,2}\./;

    function mountVCard(value) {
      const fields = {};
      var phone = '';

      value.split(/\r\n|\r|\n/).forEach(function (line) {
        let results, key;

        if (Re1.test(line)) {
          results = line.match(Re1);
          key = results[1].toLowerCase();
          fields[key] = results[2];
        } else if (Re2.test(line) || Re3.test(line)) {
          let meta = {};
          let value = [];

          let vars = [];

          if (Re2.test(line)) {
            results = line.match(Re2);

            key = results[1].replace(ReKey, '').toLowerCase();
            vars = results[2].split(';');
            value = results[3].split(';');
          } else {
            results = line.match(Re3);

            key = results[1].toLowerCase();
            value = [results[2]];
          }

          if (!fields[key]) fields[key] = [];

          fields[key].push({
            meta: meta,
            value: value,
          });
        }
      });

      if (typeof fields.tel != 'undefined') {
        phone = fields.tel[0].value[0].replace(/[^0-9]/g, '');
      } else if (typeof fields.cel != 'undefined') {
        phone = fields.cel[0].value[0].replace(/[^0-9]/g, '');
      }

      return {
        name: typeof fields.fn != 'undefined' ? fields.fn : null,
        phone: phone,
        email:
          typeof fields.email != 'undefined' ? fields.email[0].value[0] : null,
      };
    }

    if (messageType === 'multi_vcard') {
      const vCardsMounted = [];

      // Montando array de vcards
      const _input_multi = input.split('BEGIN:VCARD');

      if (Array.isArray(_input_multi)) {
        _input_multi.map((item) => {
          if (item?.toString()?.length > 0) {
            // Add no array cada vcard formatado
            const _value = `BEGIN:VCARD${item}`;
            vCardsMounted.push(mountVCard(_value));
          }
        });
      }

      // retornando vcards em forma de array
      message.vCard = vCardsMounted;
    } else {
      message.vCard = mountVCard(input);
    }
  }

  ////isMedia
  message.isMedia =
    messageType === 'audio' ||
    messageType === 'ptt' ||
    messageType === 'video' ||
    messageType === 'image' ||
    messageType === 'sticker' ||
    messageType === 'application' ||
    messageType === 'document' ||
    messageType === 'compacted';
  message.messageType = messageType;
  message.documentType = messageType;

  if (messageType === 'url' || messageType === 'link') {
    message.documentType = 'link';
  } else if (messageType === 'image' || messageType === 'sticker') {
    message.documentType = 'image';
  } else if (messageType === 'audio' || messageType === 'ptt') {
    message.documentType = 'audio';
  } else if (
    messageType === 'application' ||
    messageType === 'document' ||
    messageType === 'compacted'
  ) {
    /\.(zip|rar|7z)$/i.test(message.mimeType?.replace(/\//, '.'))
      ? (message.documentType = 'compacted')
      : /\.(doc|docx|odt|txt|document)$/i.test(
          message.mimeType?.replace(/\//, '.')
        )
      ? (message.documentType = 'textEditor')
      : /\.(xls|xlsx|csv|sheet)$/i.test(message.mimeType?.replace(/\//, '.'))
      ? (message.documentType = 'textSheet')
      : /\.(ppt|pptx|odp|presentation)$/i.test(
          message.mimeType?.replace(/\//, '.')
        )
      ? (message.documentType = 'apresentation')
      : /\.(pdf)$/i.test(message.mimeType?.replace(/\//, '.'))
      ? (message.documentType = 'pdf')
      : (message.documentType = 'file');
  }

  if (messageType === 'product') {
    if (message.text && (message.text + '').substr(0, 1) == '{') {
      message.product = JSON.parse(message.text);
    } else if (message.body && (message.body + '').substr(0, 1) == '{') {
      message.product = JSON.parse(message.body);
    } else {
      message.product = null;
    }
  }

  if (messageType === 'payment_link') {
    if (message.text && (message.text + '').substr(0, 1) == '{') {
      message.payment = JSON.parse(message.text);
    } else if (message.body && (message.body + '').substr(0, 1) == '{') {
      message.payment = JSON.parse(message.body);
    } else {
      message.payment = null;
    }
  }

  return message;
}
function maskPhone(number) {
  let ddi = number.substr(0, 2);
  if (ddi === '55') {
    //Brasil
    let ddd = number.substr(2, 2);
    number = number.substr(4);
    if (number.length === 8) {
      number = number.substr(0, 4) + '-' + number.substr(4);
    } else {
      number = number.substr(0, 5) + '-' + number.substr(5);
    }
    return '+55 (' + ddd + ') ' + number;
  }
  return number;
}
export function treatObjectFile(file) {
  if (file) {
    let newName = file.name;
    let newPath = file.path;
    let lastOccurrenceIndex, namePart, extensionPart;

    // Regras para retirar todos os caracteres especiais e espaços do arquivo
    if (file.path) {
      lastOccurrenceIndex = file.path.lastIndexOf('.');
      namePart = file.path.substr(0, lastOccurrenceIndex);
      extensionPart = file.path.substr(lastOccurrenceIndex);

      newPath = `${namePart
        .toLowerCase()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9s]/g, '')}${extensionPart}`;
    }
    if (file.name) {
      lastOccurrenceIndex = file.name.lastIndexOf('.');
      namePart = file.name.substr(0, lastOccurrenceIndex);
      extensionPart = file.name.substr(lastOccurrenceIndex);

      newName = `${namePart
        .toLowerCase()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9s]/g, '')}${extensionPart}`;
    }

    let fileObjectAfterTreatment = {
      file,
      id: uuidv4(),
      mimeType: file.type,
      typeFile: file.type.split(/\//)[0],
      subTypeFile: file.type.split(/\//)[1],
      name: newName,
      path: newPath,
      size: file.size,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    };

    return fileObjectAfterTreatment;
  } else {
    return null;
  }
}
export function treatChannel(channel) {
  if (!channel.type) {
    switch (channel.channel_id) {
      case 1:
        channel.type = 'whatsapp';
        break;
      case 2:
        channel.type = 'facebook';
        break;
      case 3:
        channel.type = 'email';
        break;
      case 4:
        channel.type = 'webchat';
        break;
      case 5:
        channel.type = 'instagram';
        break;
      case 6:
        channel.type = 'waba';
        break;
      default:
        channel.type = null;
        break;
    }

    // Incluido waba que era broker
    if (channel?.api == 33 || channel?.api == 34) {
      channel.type = 'waba';
    }
  }
  if (!channel.icon_fill) {
    switch (channel.type) {
      case 'whatsapp':
        if (channel.api && (channel.api === 33 || channel.api === 34)) {
          channel.icon = 'poli-icon pi-whatsapp-api-fill';
          channel.icon_fill = 'poli-icon pi-whatsapp-api-fill';
          channel.icon_line = 'poli-icon pi-whatsapp-api-line';
          channel.icon_color = '#00C48C';
        } else {
          channel.icon = 'poli-icon pi-whatsapp-fill';
          channel.icon_fill = 'poli-icon pi-whatsapp-fill';
          channel.icon_line = 'poli-icon pi-whatsapp-line';
          channel.icon_color = '#00C48C';
        }
        break;
      case 'facebook':
        channel.icon = 'poli-icon pi-messenger-fill';
        channel.icon_fill = 'poli-icon pi-messenger-fill';
        channel.icon_line = 'poli-icon pi-messenger-line';
        channel.icon_color = '#0084F4';
        break;
      case 'email':
        channel.icon = 'poli-icon pi-mail-fill';
        channel.icon_fill = 'poli-icon pi-mail-fill';
        channel.icon_line = 'poli-icon pi-mail-line';
        channel.icon_color = '#ff665d';
        break;
      case 'webchat':
        channel.icon = 'poli-icon pi-webchat-fill';
        channel.icon_fill = 'poli-icon pi-webchat-fill';
        channel.icon_line = 'poli-icon pi-webchat-line';
        channel.icon_color = '#FFCA5D';
        break;
      case 'instagram':
        channel.icon = 'poli-icon pi-instagram-fill';
        channel.icon_fill = 'poli-icon pi-instagram-fill';
        channel.icon_line = 'poli-icon pi-instagram-line';
        channel.icon_color = '#FFCA5D';
        break;
      case 'waba':
        channel.icon = 'poli-icon pi-whatsapp-api-fill';
        channel.icon_fill = 'poli-icon pi-whatsapp-api-fill';
        channel.icon_line = 'poli-icon pi-whatsapp-api-line';
        channel.icon_color = '#00C48C';
        break;
      default:
        channel.icon = '';
        channel.icon_fill = '';
        channel.icon_line = '';
        break;
    }
  }
  if (channel.data && typeof channel.data === 'string') {
    channel.data = JSON.parse(channel.data);
  }
  if (!channel.name) {
    let parts = channel.uid.split('@');
    channel.name = parts[0] || channel.uid;
    if (channel.type === 'whatsapp') {
      if (parts[1] == 'g.us') {
        channel.name = 'Grupo';
      } else {
        channel.name = maskPhone(parts[0]);
      }
    } else if (parts[1]) {
      if (parts[1] == 'facebook.page') {
        channel.name = 'Pág. Facebook';
      } else if (parts[1] == 'polichat.webchat.contact.system') {
        channel.name = 'Webchat system';
      }
      if (parts[1] == 'polichat.webchat.contact.client') {
        channel.name = 'Visitante';
      }
    }
  }
}
export function getTypeChannel(channel) {
  treatChannel(channel);
  return channel.type;
}
/////////////////

export function timeHasPassed(time) {
  if ((time + '').length === 13) {
    time = Math.floor(time / 1000);
  }
  if (time) {
    let now = Math.floor(Date.now() / 1000);
    let diff = now - time;

    if (diff > 0) {
      let mins = Math.floor(diff / 60);

      if (mins <= 60) {
        if (mins <= 1) {
          return `Há 1 minuto`;
        } else {
          return `Há ${mins} minutos`;
        }
      } else {
        let hours = Math.floor(mins / 60);
        if (hours <= 24) {
          if (hours > 1) return `Há ${hours} horas`;
          else return 'Há 1 hora';
        } else {
          let days = Math.floor(hours / 24);
          if (days <= 6) {
            if (days > 1) return `Há ${days} dias`;
            else return 'Há 1 dia';
          } else {
            if (days === 7) {
              return `Há 1 semana`;
            } else {
              function addZero(number) {
                if (number <= 9) {
                  return '0' + number;
                }
                return number;
              }
              const actualDate = new Date(time * 1000);
              const formatedDate =
                addZero(actualDate.getDate().toString()) +
                '/' +
                addZero(actualDate.getMonth() + 1).toString() +
                '/' +
                actualDate.getFullYear();
              return formatedDate;
              /*
              let months = Math.floor(days / 30);
              if (months <= 1) {
                return `Há 1 mês`;
              } else {
                if (months < 12) {
                  return `Há ${months} meses`;
                } else {
                  if (months === 12) {
                    return `Há 1 ano`;
                  } else {
                    return `Há mais de 1 ano`;
                  }
                }
              }
              */
            }
          }
        }
      }
    } else if (diff === 0) {
      return `Agora`;
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function createListenerPaste() {
  let qtde = 0;
  let loop = 0;

  window.document.body.addEventListener('paste', (e) => {
    if (e.path) {
      if (e.path[0].nodeName === 'INPUT') {
        return;
      }
    }
    let files = [];
    for (let i = 0; i < e.clipboardData.items.length; i++) {
      let item = e.clipboardData.items[i];
      if (item.type.indexOf('image') != -1) {
        qtde++;
        if (item.getAsFile()) {
          files.push(treatObjectFile(item.getAsFile()));
        } else {
          toast.error('Formato inválido. Apenas imagens são permitidas.');
        }
      }
    }

    if (files.length > 0) {
      if (qtde > 0 && loop != 1) {
        loop++;
        e.stopPropagation();
        e.preventDefault();
        store.dispatch(addUploadToList(null, files));
      }
    }
    qtde = 0;
    loop = 0;
  });
  return true;
}

export function createListenerDrag() {
  window.document.body.addEventListener('dragenter', (e) => {
    if (e.path) {
      if (e.path[0].nodeName === 'INPUT') {
        return;
      }
    }
    e.stopPropagation();
    e.preventDefault();
    store.dispatch(updateChatDropzone(null, true));
  });
  return true;
}

export function addChatToChatList(
  activeWallet = false,
  new_chats = [],
  existing_chats = [],
  channels = [],
  contacts = [],
  menus,
  isPendences = false
) {
  if (new_chats && new_chats.length) {
    new_chats.forEach((newChat) => {
      if (newChat && newChat.contact && newChat.contact.id) {
        let id = newChat.contact.id;

        // Se o novo chat não existe dentro da lista de CONTATOS carregados
        if (!contacts.find((contact) => contact.id === newChat.contact.id)) {
          // Caso o contato não tenha nome será atribuído o número de telefone
          if (
            (newChat.contact.name === null || newChat.contact.name == '') &&
            newChat.contact.phone
          ) {
            newChat.contact.name = formatPhone(newChat.contact.phone);
          }

          newChat.contact.picture = checkAvatar(
            newChat.contact.name,
            newChat.contact.picture
          );
          if (newChat.contact.externals) {
            newChat.contact.externals.map((external) => {
              external.type = getTypeChannel(external);
            });
          }

          // Obriga a sempre ter o contato dos chats na lista de contatos
          if (!activeWallet) {
            contacts.push(newChat.contact);
          }
        }

        // Criando o contador de novas mensagens (usado na bolinha para voltar para baixo)
        if (!newChat.newMsgCounter) {
          newChat.newMsgCounter = 0;
        }

        // Atribui os menus no novo chat
        newChat.view = { menus: menus };

        newChat = matchChannelToExternal(newChat, channels);

        if (newChat?.contact?.externals) {
          newChat.contact.externals.map((external) => {
            external.type = getTypeChannel(external);
          });
        }

        // Caso o contato não tenha nome será atribuído o número de telefone
        if (
          (newChat.contact.name === null || newChat.contact.name == '') &&
          newChat.contact.phone
        ) {
          newChat.contact.name = formatPhone(newChat.contact.phone);
        }

        // Se o usuário não tiver avatar será atribuído as Iniciais do Nome
        newChat.contact.picture = checkAvatar(
          newChat.contact.name,
          newChat.contact.picture
        );

        let chatFoundIndex = existing_chats.findIndex((ch) => ch.id === id);

        // Acrescenta o novo chat à lista de Chats Carregados
        if (chatFoundIndex < 0) {
          existing_chats.push(newChat);
        }

        // Atualizar chats existente em pendencias
        if (chatFoundIndex >= 0 && isPendences) {
          newChat.chat.chat_read = 0;
          existing_chats[chatFoundIndex] = newChat;
        }
      }
    });

    return existing_chats;
  }

  return existing_chats;
}

export function canSendMessage(opennedChat) {
  if (
    opennedChat &&
    opennedChat.chat &&
    opennedChat.view &&
    opennedChat.view.selectedChannel &&
    opennedChat.contact &&
    opennedChat.contact.externals &&
    opennedChat.contact.externals.length
  ) {
    return true;
  }

  return false;
}

export function getOperatorName(users, user_id) {
  if (users && user_id) {
    let user = users.find((user) => user.id === user_id);
    if (user) {
      return user.name;
    }
  }

  return 'Sem atendente';
}

export function handleMergeDepartmentToUsers(allDepartments, users) {
  let departmentsMergedWithUsers = [];

  // Criando o array que contem departamento e usuarios separados
  if (allDepartments && allDepartments.length && users && users.length) {
    let idsUsersHaveDept = [];

    for (let i = 0; i < allDepartments.length; i++) {
      let department = allDepartments[i];
      let usersDept = [];
      for (let j = 0; j < users.length; j++) {
        const haveDept = users[j]?.departments?.find(
          (dept) => dept.id === allDepartments[i].id
        );
        if (haveDept) {
          usersDept.push(users[j]);
          idsUsersHaveDept.push(users[j].id);
        }
      }

      // So entra se o departamento tiver usuarios
      if (usersDept?.length > 0) {
        departmentsMergedWithUsers.push({
          department,
          users: usersDept,
        });
      }
    }

    /**
     * Usuarios sem departamentos
     */
    let usersWithoutDept = [];

    users.map((user) => {
      if (!idsUsersHaveDept.includes(user.id) && user.departments == 0) {
        usersWithoutDept.push(user);
      }
    });

    if (usersWithoutDept?.length > 0) {
      const withoutDept = {
        name: 'Atendentes sem departamento',
        usersWithoutDept: true,
      };

      departmentsMergedWithUsers.unshift({
        department: withoutDept,
        users: usersWithoutDept,
      });
    }
  }

  // Função para ordenar array em ordem alfabética
  const sortByDepartments = (a, b) => {
    if (a.department.name < b.department.name) return -1;
    if (a.department.name > b.department.name) return 1;
    return 0;
  };

  departmentsMergedWithUsers =
    departmentsMergedWithUsers.sort(sortByDepartments);
  return departmentsMergedWithUsers;
}
export function hasProductInStock(products, productId, currentAmount) {
  const stockProduct = products.find((product) => product.id === productId);

  const stockProductAmount = stockProduct?.quantity;

  const amount = currentAmount;

  if (stockProductAmount == 0 || amount > stockProductAmount) {
    toast.error('Quantidade solicitada fora de estoque!');
    return false;
  }

  return stockProduct;
}

export function syncChatShoppingCartToServer(chat, cart_id) {
  if (!chat || !chat.cart || chat.cart.id != cart_id) return;

  if (!window.lastSetTimeoutSyncShopCartServer)
    window.lastSetTimeoutSyncShopCartServer = {};

  let setTimeoutIndexName = 'chat' + chat.id + 'cart' + chat.cart.id;

  clearTimeout(window.lastSetTimeoutSyncShopCartServer[setTimeoutIndexName]);

  window.lastSetTimeoutSyncShopCartServer[setTimeoutIndexName] = setTimeout(
    () => {
      if (chat.cart.needSyncToServer) {
        store.dispatch(
          markChatShopppingCartServerSynced(chat.id, chat.cart.id)
        );
        store.dispatch(
          updateShopppingCart(chat.id, chat.cart.id, {
            products: chat.cart.products,
            address: chat.cart.address,
            shipping_cost: chat.cart.shipping_cost,
            integration_id: chat.cart.integration_id,
          })
        );
      }
    },
    800
  );
}

export function convertActiveTabInScreen(tabNavigation) {
  if (tabNavigation === 'unreads') {
    return 'noread';
  } else if (tabNavigation === 'chat') {
    return 'all';
  } else if (tabNavigation === 'inprogress') {
    return 'currents';
  }
}

export const contentErrorHTML = (type) => {
  return (
    `
  <div>
    <div className="mx-auto text-center mb-1" style="border-radius: 50%; background: white; width: fit-content; margin-top: 5px; margin-left: 5px;">
      <i className="poli-icon pi-poly-crazy-fill	" style="color: var(--activated-icons-color); font-size: 45px;"></i>
    </div>
    <p>Ops, algo deu errado, ` +
    type +
    `</p>
  </div>`
  );
};

export const hashHistoryMobile = (id) => {
  if (window) {
    const title = `chat #${id}`;
    const url = window.location.pathname + `#${id}`;
    window.history.pushState({}, title, url);
  }
};

export const removeHashChat = () => {
  if (window && window.location.hash) {
    const title = `Chats`;
    const url = window.location.pathname;
    window.history.pushState({}, title, url);
  }
};

export const removeAccentString = (value) => {
  const mapAccentsHex = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g,
  };

  for (var letter in mapAccentsHex) {
    const regularExpression = mapAccentsHex[letter];
    value = value.replace(regularExpression, letter);
  }

  return value;
};
